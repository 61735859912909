<template>
	<div class="about-detail">
		  <h2 id="intro01">{{detail.name}}</h2>
      <div v-html="detail.content"></div>
       <!-- <p>国家增材制造创新中心是工信部落实《中国制造2025》战略，首批布局的国家制造业创新中心之一，拥有一支由中国工程院卢秉恒院士领衔、以国家级专家张丽娟为技术带头人、以高级工程师和博士为骨干的高端自主研发团队。目前，团队拥有中国工程院院士2名、国家级特聘专家8名、博硕士技术人员230多人，申请专利超30项。形成一支专业、学历和年龄结构合理，多学科综合交叉的研究开发队伍，实现了从机械结构、控制系统、软件、打印材料、创新设计、产业应用等方面的协同和融合创新。</p>

       <div class="ultitle">卢秉恒</div>
       <img style="width:200px; margin:5px 0;" :src="require('../../assets/about/002.jpg')" />
       <ul>
          <li>中国工程院院士</li>
          <li>国家增材制造创新中心主任</li>
          <li>国家“973”基础研究项目首席科学家</li>
          <li>国务院学术委员会机械学科评议组组长</li>
          <li>全国增材制造标准化技术委员会主任</li>
          <li>太空探索实验室科学委员会共同主席</li>
          <li>钱学森空间技术实验室学术委员</li>
          <li>全国高校金属切削机床学会理事长</li>
          <li>中国机械工程学会副理事长</li>
       </ul>
       <div class="ultitle">张丽娟</div>
       <img style="width:200px; margin:5px 0;" :src="require('../../assets/about/005.jpg')" />
       <ul>
         <li>牛津大学博士、剑桥大学博士后</li>
         <li>国家增材制造创新中心副总工程师</li>
          <li>曾任国家金属线材制品工程技术研究中心副主任</li>
          <li>主持欧盟FP07、英国EPSRC、国家863及省部级科研项目 30余项，相关授权专利10余项</li>
          <li>主持工信部强基工程项目（增材制造专用阵列式电子枪），项目经费9075万元</li>
          <li>主持JW科技委项目（舰载打印增减材一体化装备），项目经费1500万元</li>
          <li>负责西安增材制造研究院项目（大型金属构件增材制造），扶持资金4500万元</li>
       </ul>
       <div class="ultitle">高端制造装备协同创新中心</div>
       <p>高端制造装备协同创新中心经教育部、财政部认定批准，由西安交通大学牵头，联合国内具备学科优势的浙江大学、华中科技大学、大连理工大学以及龙头机床企业沈阳机床、大连机床、秦川机床、昆明机床、华中数控及两家行业牵头研究所北京机床所与轴研科技，并整合04专项建设的7个相关技术创新平台组建而成。</p>
       <p>中心是制造装备领域产学研最高水平创新资源的汇聚，代表了国内制造领域的顶级水平，在高速高效加工装备、精密超精密加工装备、3D打印、两机工程等高端制造业领域，建立了多学科融合、多团队协同、多技术集成、产学研用长效合作的技术研发与应用平台，形成支撑我国高端制造装备产业核心技术和创新技术的研发高地。</p>
       <p>作为国家增材制造创新中心和高端制造装备协同创新中心的依托单位和承载主体，广东增减材科技有限公司在增减材复合制造技术的研发应用方面一直处于行业先进水平，已成功为航天航空、汽车制造、精细模具等领域众多客户提供可靠服务。</p> -->
	</div>
</template>

<script>
import { ref,onMounted,watch,toRefs } from 'vue'
import {useRoute} from 'vue-router'

export default {
	name: '',
	components:{},
	props:{
    list:{type:Array,default:[]}
  },
  setup(props){
    const route = useRoute();
    const detail = ref({content:'', name:''});
    const { list } = toRefs(props);
    onMounted(()=>{
      if(route.path==='/about/ab2'&&list.value.length>0){ 
        detail.value = list.value[1]
      }
    })
    watch(list,()=>{
      detail.value = list.value[1]
    })
    return {detail}
  }
}
</script>

<style scoped type="text/css">
  #intro01{font-size:22px; color:#111; font-weight: bold; padding:5px 0; text-align:center; margin:10px 0 20px 0; border-bottom:1px solid #eee;}
</style>